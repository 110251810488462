import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import Program from "./Program";
import SimpleSpinner from "./SimpleSpinner";
import "./ProgramCarousel.scss";
import "./_series-card.scss";
import "../assets/styles/react-multi-carousel.scss";

// Breakpoints taken from Areena's styles
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    centerMode: true
  },
  tablet: {
    breakpoint: { max: 1024, min: 561 },
    items: 1,
    centerMode: true
  },
  mobile: {
    // before 464
    // newer 639
    // best? 560
    breakpoint: { max: 560, min: 0 },
    items: 1,
    centerMode: true
  }
};

const ProgramCarousel = ({
  programs,
  chosenTagsEmpty,
  handleProgramClick,
  loading
}) => {
  const emptyText =
    "Valitse ylläolevasta listasta fiilistunnisteita, niin suosittelemme sinulle sopivia ohjelmia.";
  const noSuggestionsText =
    "Näillä tunnisteilla ei valitettavasti löytynyt ollenkaan sisältöä";

  if (loading) {
    return (
      <div className="program-container program-container__empty">
        <SimpleSpinner></SimpleSpinner>
      </div>
    );
  }
  if (chosenTagsEmpty) {
    return (
      <div className="program-container program-container__empty">
        {emptyText}
      </div>
    );
  }
  if (programs.length === 0) {
    return (
      <div className="program-container program-container__empty">
        {noSuggestionsText}
      </div>
    );
  } else {
    return (
      <Carousel
        partialVisible={true}
        responsive={responsive}
        containerClass={"program-container"}
        swipeable={true}
        keyBoardControl={true}
        itemClass={"program"}
      >
        {programs.map(pro => {
          return (
            <Program
              key={pro.targetId}
              title={pro.title}
              id={pro.targetId}
              transmissionTitle={pro.transmissionTitle}
              imageUrl={pro.imageUrl}
              handleClick={handleProgramClick}
            ></Program>
          );
        })}
      </Carousel>
    );
  }
};

ProgramCarousel.propTypes = {
  programs: PropTypes.array.isRequired,
  chosenTagsEmpty: PropTypes.bool.isRequired,
  handleProgramClick: PropTypes.func.isRequired
};

export default ProgramCarousel;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('dotenv').config();

window.yleTagManager = function () {
    function e(n, t) {
        var e = document.createElement("script"), i = document.getElementsByTagName("script")[0];
        e.async = 1;
        e.src = n;
        i.parentNode.insertBefore(e, i);
        e.onload = function () {
            "function" == typeof t && t()
        }
    }
    return {
        initializeAnalytics: function (n, t) {
            e(function (n, t) {
                return "//" + function (n) { return "production" === n ? "tag-manager.yle.fi" : "test" === n ? "tag-manager-test.yle.fi" : "" }(t) + "/" + function (n, t) { return n + "-" + t + ".js" }(n, t)
            }(n, t))
        }, loadAnalyticsScript: e
    }
}();


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

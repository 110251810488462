import React from "react";
import PropTypes from "prop-types";
import "./TextContainer.scss";
import "./Tag.scss";

const TextContainer = ({
  text,
  boldedText,
  bgColor,
  textColor,
  hasLeftBorder,
  isAlignCenter,
  linkRef,
  linkText
}) => {
  const styles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: bgColor,
    borderLeft: hasLeftBorder ? "14px solid #ECB732" : "",
    paddingLeft: hasLeftBorder ? "18px" : "",
    color: textColor ? textColor : "",
    textAlign: isAlignCenter ? "center" : "left"
  };

  const makeTag = (linkText, linkRef) => {
    if (linkRef) {
      return (
        <a
          style={{ color: textColor }}
          className="tag tag-text text__bolded text-link"
          href={linkRef}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>
      );
    } else return <span>{linkText}</span>;
  };

  return (
    <div className="text-container" style={styles}>
      {text}
      {boldedText ? <div className="text__bolded">{boldedText}</div> : ""}

      {linkText && linkRef ? makeTag(linkText, linkRef) : ""}
    </div>
  );
};

TextContainer.propTypes = {
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  hasLeftBorder: PropTypes.bool,
  isAlignCenter: PropTypes.bool,
  isTextBolded: PropTypes.bool,
  linkRef: PropTypes.string,
  linkText: PropTypes.string
};

export default TextContainer;

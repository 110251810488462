import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';
import './TagContainer.scss';

function isChosen(number, chosenTags) {
    let isTagChosen = false;
    for (let tag of chosenTags){
        if(number === tag) {
            isTagChosen = true;
        }
    }
    return isTagChosen;
}

const TagContainer = ({tags, chosenTags, handleTagClick}) => {
    return (<div className='tag-container'>
        {Object.keys(tags).map((tagKey) => {
            let tagNumber = tags[tagKey];
            let isTagChosen = isChosen(tagNumber, chosenTags);
            return <Tag text={tagKey} id={tagNumber} key={tagNumber} isChecked={isTagChosen} handleClick={handleTagClick}></Tag>
        })}
        </div>);
}

TagContainer.propTypes = {
    tags: PropTypes.object.isRequired,
    chosenTags: PropTypes.array.isRequired,
    handleTagClick: PropTypes.func.isRequired,
}

export default TagContainer;
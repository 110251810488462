import React from 'react';
import './Button.scss';

const Button = ({text, onClickFunc}) => {
    return (<div className='button-container'>
        <button className='button button-text' onClick={onClickFunc}>
            {text}
        </button>
    </div>);
}

export default Button;
import React from "react";
import Button from "./components/Button";
import FloatingPlayer from "./components/FloatingPlayer";
import Header from "./components/Header";
import ProgramCarousel from "./components/ProgramCarousel";
import TagContainer from "./components/TagContainter";
import TextContainer from "./components/TextContainer";
import "./Variables.scss";
import Divider from "./components/Divider";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.getTagTextForId = this.getTagTextForId.bind(this);
    this.handleTagReset = this.handleTagReset.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleProgramClick = this.handleProgramClick.bind(this);
    this.printChosenTags = this.printChosenTags.bind(this);
    this.printSuggestedPrograms = this.printSuggestedPrograms.bind(this);
    this.printCurrentProgramId = this.printCurrentProgramId.bind(this);
    this.fetchProgramDataByChosenTags = this.fetchProgramDataByChosenTags.bind(
      this
    );
    this.fetchSeriesDataBy = this.fetchSeriesDataBy.bind(this);
    this.filterAudioPrograms = this.filterAudioPrograms.bind(this);
    this.countHowManyEmoTagsProgramHas = this.countHowManyEmoTagsProgramHas.bind(
      this
    );
    this.filterRelevantData = this.filterRelevantData.bind(this);
    this.trackEventWhenAnalyticsAvailable = this.trackEventWhenAnalyticsAvailable.bind(
      this
    );
    this.trackPageWhenAnalyticsAvailable = this.trackPageWhenAnalyticsAvailable.bind(
      this
    );
    this.getTargetIdsFromPrograms = this.getTargetIdsFromPrograms.bind(this);

    const chosenTagsSet = new Set();

    this.state = {
      fetchInProgress: false,
      chosenTags: chosenTagsSet,
      allTags: {
        aggressiivinen: "18-304743",
        ahdistava: "18-301480",
        analyyttinen: "18-303267",
        asiallinen: "18-304738",
        energinen: "18-304745",
        extreme: "18-299345",
        haikea: "18-301481",
        hauska: "18-299339",
        huoleton: "18-304737",
        hurmaava: "18-299346",
        inspiroiva: "18-304740",
        jännittävä: "18-299343",
        kevyt: "18-299341",
        koskettava: "18-299344",
        kriittinen: "18-299348",
        lämminhenkinen: "18-299351",
        nokkela: "18-299347",
        nostalginen: "18-304744",
        pelottava: "18-299342",
        pohdiskeleva: "18-303268",
        provosoiva: "18-304742",
        rauhallinen: "18-299350",
        riemukas: "18-304741",
        surullinen: "18-304739",
        synkkä: "18-299340",
        vakava: "18-301479",
        vauhdikas: "18-299349"
      },
      introText:
        "Löydä kiinnostavaa kuunneltavaa fiilispohjalta Yle Areenan audio-ohjelmista.",
      devPropmt:
        "Tunnelmahaku on kehityksen alkuvaiheessa ja käytät nyt ensimmäistä testiversiota.",
      devBgColor: "#E17932",
      feedbackPrompt: "Käyttäisitkö jatkossa Tunnelmahakua? Mitä muuttaisit?",
      feedbackBgColor: "#E17932",
      feedbackTextColor: "#FFFFFF",
      suggestedPrograms: [],
      currentProgramId: ""
    };
  }

  trackEventWhenAnalyticsAvailable(eventName, settings, i = 1) {
    const timeout = 5;
    const interval = 100;
    if (i * interval > timeout * 1000) {
      if (process.env.NODE_ENV !== "production") {
        console.log("yleAnalytics unavailable - trackEvent:", eventName);
      }
      return;
    }
    if (typeof window.yleAnalytics === "undefined") {
      setTimeout(() => {
        this.trackEventWhenAnalyticsAvailable(eventName, i + 1);
      }, interval);
      return;
    }
    //console.log('can call window.yleAnalytics.trackEvent() ', eventName);
    window.yleAnalytics.trackEvent(eventName, settings);
  }

  trackPageWhenAnalyticsAvailable(pageName, i = 1) {
    const timeout = 5;
    const interval = 100;
    if (i * interval > timeout * 1000) {
      if (process.env.NODE_ENV !== "production") {
        console.log("yleAnalytics unavailable - trackPage:", pageName);
      }
      return;
    }
    if (typeof window.yleAnalytics === "undefined") {
      setTimeout(() => {
        this.trackPageWhenAnalyticsAvailable(pageName, i + 1);
      }, interval);
      return;
    }
    //console.log('can call window.yleAnalytics.trackPage() ', pageName);
    window.yleAnalytics.trackPage(pageName);
  }

  componentDidMount() {
    this.fetchProgramDataByChosenTags();
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://player-v2.yle.fi/embed.js";
    document.body.appendChild(script);
    // Initializing analytics after yleTagManager has downloaded
    if (window.yleTagManager && window.yleTagManager.initializeAnalytics) {
      window.yleTagManager.initializeAnalytics("tunnelmahaku", "production");
      this.trackPageWhenAnalyticsAvailable("tunnelmahaku-etusivu");
    }
  }

  handleProgramClick(e) {
    e.preventDefault();
    let programId = e.target.id;
    if (programId) {
      this.setState(
        { currentProgramId: programId },
        this.printCurrentProgramId
      );
    }
  }

  handleTagReset() {
    this.setState(
      { fetchInProgress: false, chosenTags: new Set(), suggestedPrograms: [] },
      this.fetchProgramDataByChosenTags
    );
  }

  printCurrentProgramId() {
    console.log("current program id is ", this.state.currentProgramId);
  }

  printChosenTags() {
    console.log("chosen tags are ", this.state.chosenTags);
  }

  printSuggestedPrograms() {
    console.log("suggested programs are ", this.state.suggestedPrograms);
  }

  filterAudioPrograms(array) {
    const result = array.filter(program =>
      program.targetId.toString().startsWith("1-")
    );
    return result;
  }

  countHowManyEmoTagsProgramHas(array) {
    let program;
    let programsWithTagCount = new Set();
    if (this.state.chosenTags.size > 1) {
      for (let j = 0; j < array.length; j++) {
        program = array[j];
        let curTargetId = program.targetId.toString();
        let copy = [...array]; // Note, haven't removed the current program itself, so there will always be ONE match
        const programsWithSameTargetId = copy.filter(
          p => p.targetId.toString() === curTargetId
        );
        const amount = programsWithSameTargetId.length;
        // Hence there's always one match, amount should be larger to actually have
        if (amount >= 2) {
          programsWithTagCount.add({
            targetId: program.targetId,
            numberOfTags: amount
          });
          // Delete
          for (let po of programsWithSameTargetId) {
            array.splice(array.indexOf(po), 1);
          }
        }
      }
    } else {
      for (let item of array) {
        programsWithTagCount.add({ targetId: item.targetId, numberOfTags: 1 });
      }
    }
    return Array.from(programsWithTagCount);
  }

  filterRelevantData(targetId, numberOfTags, json) {
    let type = json.type.toString();
    // Based on discussion on Thursday 19.12. RadioSeries was removed
    // Based on discussion on Friday 20.12. RadioContent was removed
    if (type === "RadioProgram" || type === "RadioClip") {
      let result = {
        targetId: targetId,
        numberOfTags: numberOfTags,
        areenaLink: `https://areena.yle.fi/${targetId}`
      };
      // Episode of some series
      if (json.partOfSeries) {
        result.partOfSeries = json.partOfSeries;
        if (json.image) {
          result.image = json.image;
          result.imageUrl = `https://images.cdn.yle.fi/image/upload/c_fill,d_yle-areena.jpg,f_auto,fl_lossy,q_auto:eco,w_640/v${result.image.version}/${result.image.id}.jpg`;
        } else if (json.partOfSeries.squareImage) {
          result.image = json.partOfSeries.squareImage;
        } else if (json.partOfSeries.image) {
          result.image = json.partOfSeries.image;
        }
        if (json.partOfSeries.title.sv || json.partOfSeries.title.se) {
          return null;
        }

        result.title = json.partOfSeries.title.fi;
        let transmissionTitle =
          "Jakso" +
          (json.episodeNumber !== undefined ? " " + json.episodeNumber : "") +
          ": " +
          json.title.fi;
        result.transmissionTitle = transmissionTitle;
        result.imageUrl = `https://images.cdn.yle.fi/image/upload/c_fill,d_yle-areena.jpg,f_auto,fl_lossy,q_auto:eco,w_640/v${result.image.version}/${result.image.id}.jpg`;
      }
      // Not an episode of series, an individual program or clip
      else {
        if (json.title.sv || json.title.se) {
          return null;
        }
        result.title = json.title.fi;
        result.image = json.image;
        result.imageUrl = `https://images.cdn.yle.fi/image/upload/c_fill,d_yle-areena.jpg,f_auto,fl_lossy,q_auto:eco,w_640/v${result.image.version}/${result.image.id}.jpg`;
        if (json.transmissionTitle) {
          result.transmissionTitle = json.transmissionTitle.fi;
        } else if (json.shortDescription) {
          result.transmissionTitle = json.shortDescription.fi;
        }
      }
      if (json.type) {
        result.type = json.type;
      }

      return result;
    }
    return null;
  }

  findNumberOfTags(seriesId, targetId, programIdsWithTagAmounts) {
    const correctProgram = programIdsWithTagAmounts.filter(
      program => program.targetId === targetId || program.targetId === seriesId
    );
    if (correctProgram[0]) {
      return correctProgram[0].numberOfTags;
    }
    return 0;
  }

  filterSuggestionDataFromJson(jsonArray, programIdsWithTagAmounts) {
    let results = [];
    for (let index in jsonArray) {
      let programAsJson = jsonArray[index];
      let seriesId = programAsJson.partOfSeries
        ? programAsJson.partOfSeries.id
        : "";
      let targetId = programAsJson.id;
      let numberOfTags = this.findNumberOfTags(
        seriesId,
        targetId,
        programIdsWithTagAmounts
      );

      if (numberOfTags !== 0) {
        const filteredJson = this.filterRelevantData(
          targetId,
          numberOfTags,
          programAsJson
        );
        if (filteredJson !== null) {
          results.push(filteredJson);
        }
      }
    }
    return results;
  }

  filterTargetIdsFromJson(jsonArray) {
    let results = [];
    for (let index in jsonArray) {
      let targetId = jsonArray[index].id;
      results.push(targetId);
    }
    return results;
  }

  filterTwoEpisodesFromAllSeriesJson(jsonArray) {
    let results = [];
    let episodeCount = 0;
    let episodeAmounts = new Map();
    for (let episode of jsonArray) {
      if (episodeAmounts.has(episode.partOfSeries.id)) {
        episodeCount = episodeAmounts.get(episode.partOfSeries.id);
        if (episodeCount < 2) {
          results.push(episode);
          episodeCount += 1;
          episodeAmounts.set(episode.partOfSeries.id, episodeCount);
        }
      } else {
        results.push(episode);
        episodeAmounts.set(episode.partOfSeries.id, 1);
      }
    }
    return results;
  }

  compareSentIdsToReceived(sentIds, receivedIds) {
    return [...sentIds.filter(x => receivedIds.indexOf(x) === -1)];
  }

  fetchSeriesDataBy(seriesIds, previousResponseData, programIdsWithTagAmounts) {
    let apiUrl = "";
    if (process.env.REACT_APP_PROGRAMS_API) {
      apiUrl = "" + process.env.REACT_APP_PROGRAMS_API.toString();
    }
    return fetch(
      `${apiUrl}/items.json?series=${seriesIds}&language=fi&mediaobject=audio&availability=ondemand&app_key=${process.env.REACT_APP_KEY}&app_id=${process.env.REACT_APP_ID}`
    )
      .then(response => {
        return response.json();
      })
      .catch(err => {
        console.log("Error while transforming response to json ", err);
      })
      .then(response => {
        const twoNewestEpisodes = this.filterTwoEpisodesFromAllSeriesJson(
          response.data
        );

        const seriesEpisodesAndPreviousProgramData = [
          ...twoNewestEpisodes,
          ...previousResponseData
        ];

        let suggestionData = this.filterSuggestionDataFromJson(
          seriesEpisodesAndPreviousProgramData,
          programIdsWithTagAmounts
        );

        this.setState(
          { fetchInProgress: false, suggestedPrograms: suggestionData },
          this.printSuggestedPrograms
        );
      })
      .catch(err => {
        console.log("Error while working on series data ", err);
      });
  }

  fetchEpisodeDataBySeveralIds(programIdsWithTagAmounts) {
    const onlyTargetIds = this.getTargetIdsFromPrograms(
      programIdsWithTagAmounts
    );
    let apiUrl = "";
    if (process.env.REACT_APP_PROGRAMS_API) {
      apiUrl = "" + process.env.REACT_APP_PROGRAMS_API.toString();
    }
    return fetch(
      `${apiUrl}/items.json?limit=100&language=fi&mediaobject=audio&availability=ondemand&id=${onlyTargetIds}&app_key=${process.env.REACT_APP_KEY}&app_id=${process.env.REACT_APP_ID}`
    )
      .then(response => {
        return response.json();
      })
      .catch(err => {
        console.log("Error while transforming response to json ", err);
      })
      .then(response => {
        // Compare received ids to sent ids
        const receivedIds = this.filterTargetIdsFromJson(response.data);

        const possibleSeriesIds = this.compareSentIdsToReceived(
          onlyTargetIds,
          receivedIds
        );

        if (possibleSeriesIds.length > 0) {
          this.fetchSeriesDataBy(
            possibleSeriesIds,
            response.data,
            programIdsWithTagAmounts
          );
        } else {
          const suggestionData = this.filterSuggestionDataFromJson(
            response.data,
            programIdsWithTagAmounts
          );

          this.setState(
            { fetchInProgress: false, suggestedPrograms: suggestionData },
            this.printSuggestedPrograms
          );
        }
      })
      .catch(err => {
        console.log("Error reading data: ", err);
      });
  }

  getTargetIdsFromPrograms(programs) {
    let ids = [];
    for (let po of programs) {
      ids.push(po.targetId);
    }
    return ids;
  }

  fetchProgramDataByChosenTags(callback) {
    const emoTags = Array.from(this.state.chosenTags);

    if (emoTags.length > 0) {
      let apiUrl = "";
      if (process.env.REACT_APP_RELATIONS_API) {
        apiUrl = "" + process.env.REACT_APP_RELATIONS_API.toString();
      }
      fetch(
        `${apiUrl}/relation?sourceId=${emoTags}&withAncestorRelations=true&relationType=isAtmosphereOf&app_id=${process.env.REACT_APP_ID}&app_key=${process.env.REACT_APP_KEY}`
      )
        .then(response => {
          return response.json();
        })
        .catch(err => {
          console.log("Error while transforming response to json ", err);
        })
        .then(response => {
          const aPrograms = this.filterAudioPrograms(response.data);
          if (aPrograms.length > 0) {
            const programsWithTagAmounts = this.countHowManyEmoTagsProgramHas(
              aPrograms
            );
            this.fetchEpisodeDataBySeveralIds(programsWithTagAmounts);
          } else {
            this.setState({ fetchInProgress: false, suggestedPrograms: [] });
          }
        })
        .catch(err => {
          console.log("Error reading data: ", err);
        });
    }
  }

  getTagTextForId(id) {
    const idString = id.toString();
    const allTagTexts = Object.keys(this.state.allTags);
    for (let tagText of allTagTexts) {
      let tagId = this.state.allTags[tagText];
      if (tagId === idString) {
        return tagText;
      }
    }
    return null;
  }

  handleTagChange(e) {
    e.preventDefault();

    let tagId = e.target.value;
    let settings = {
      pageName: "tunnelmahaku-etusivu"
    };
    let tagText = this.getTagTextForId(tagId);
    if (tagText !== undefined) {
      settings.labels = { yle_tunnetagi: tagText.toString() };
    }
    let copySet = new Set(this.state.chosenTags);
    if (this.state.chosenTags.has(tagId)) {
      copySet.delete(tagId);
    } else {
      copySet.add(tagId);
      const tagEventName = "tunnelmahaku.tunnesana." + tagText.toString();
      // Discussed with Milla M that only adding tag will be tracked
      this.trackEventWhenAnalyticsAvailable(tagEventName, settings);
    }

    this.setState(
      { fetchInProgress: true, chosenTags: copySet, currProgramId: "" },
      this.fetchProgramDataByChosenTags
    );
  }

  render() {
    const introText = this.state.introText;
    const allTags = this.state.allTags;
    const chosenTags = Array.from(this.state.chosenTags);
    const suggestedPrograms = this.state.suggestedPrograms;
    const devPropmt = this.state.devPropmt;
    const devBgColor = this.state.devBgColor;
    const feedbackPrompt = this.state.feedbackPrompt;
    const feedbackTextColor = this.state.feedbackTextColor;
    const chosenTagsEmpty = chosenTags.length === 0 ? true : false;
    const currProgramId = this.state.currentProgramId;
    const linkToFeedbackQuest =
      "https://docs.google.com/forms/d/e/1FAIpQLSeE-iYv3GOB3eU5Cz8XMAVb1NYtnbupmJtK4-gwiZ4mkhlwRA/viewform";
    const feedbackLinkText = "Vastaa kyselyyn";
    const fetchingOrNot = this.state.fetchInProgress;

    return (
      <div className="App">
        <Header text={"Tunnelmahaku"} smallText={"Alfa"}></Header>
        <TextContainer text={introText}></TextContainer>
        <Divider text={"Minkälaista etsit?"}></Divider>
        <TagContainer
          tags={allTags}
          chosenTags={chosenTags}
          handleTagClick={this.handleTagChange}
        ></TagContainer>
        <Divider text={"Suosituksemme"}></Divider>
        <ProgramCarousel
          loading={fetchingOrNot}
          programs={suggestedPrograms}
          chosenTagsEmpty={chosenTagsEmpty}
          handleProgramClick={this.handleProgramClick}
        ></ProgramCarousel>
        <Button
          text={"Tyhjennä valinnat"}
          onClickFunc={this.handleTagReset}
        ></Button>
        <TextContainer
          text={devPropmt}
          boldedText={feedbackPrompt}
          isAlignCenter={true}
          bgColor={devBgColor}
          textColor={feedbackTextColor}
          linkRef={linkToFeedbackQuest}
          linkText={feedbackLinkText}
        ></TextContainer>
        <FloatingPlayer id={currProgramId}></FloatingPlayer>
      </div>
    );
  }
}

export default App;

import React from "react";
import PropTypes from "prop-types";

const Program = ({ title, id, transmissionTitle, imageUrl, handleClick }) => {
  return (
    <div
      id={id}
      className="series-card series-card--normal"
      onClick={handleClick}
      style={{
        height: "220px",
        width: "100%",
        backgroundImage: `url(${imageUrl})`
      }}
    >
      <span className="series-card__content">
        <h4 id={id} className="series-card__content__title">
          {title}
        </h4>
        <span id={id} className="series-card__content__description">
          {transmissionTitle}
        </span>
      </span>
    </div>
  );
};

Program.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  transmissionTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default Program;

import React from 'react';
import './FloatingPlayer.scss';

class FloatingPlayer extends React.Component {

    componentDidUpdate() {
        const { ylePlayer, yleTunnelmaApp } = window;
        const element = this.player;
        const { id } = this.props;
    
        if (yleTunnelmaApp && yleTunnelmaApp.ylePlayer && yleTunnelmaApp.ylePlayer.embed) {
          // Allow parent site to embed the player. 
          yleTunnelmaApp.ylePlayer.embed(element, id)
        } else if (ylePlayer && ylePlayer.render) {
            // If player has been rendered before, there
            if (this.player.children.length === 0) {
                ylePlayer.render({
                element,
                props: {
                    id,
                    autoplay: true
                    },
                }).then((p) => {this.playerInstance = p;});
            }
            else {
                this.playerInstance.update({programId: id, autoplay: true});
            }
          
        } else {
          // Yle Player v1 and v2 auto embed.
          // https://github.com/Yleisradio/player-static/wiki/Player-embed-instructions#auto-embed
          // http://chili.yle.fi/confluence/pages/viewpage.action?pageId=36995969#Playerinlis%C3%A4%C3%A4minensivulle-K%C3%A4ytt%C3%B6%C3%B6notto
          // We assume the player js script is still loading and will auto-embed
          // the element when ready.
          element.className += ' yle_areena_player';
          element.setAttribute('data-id', id);
        }
      }

    render() {
        return (<div 
            id='tunnelmahaku-player-container'
            data-id={this.props.id}
            className='floating-player'
            ref={component => {
                this.player = component
              }}
        >
        </div>);
    }
    
}

export default FloatingPlayer;
import React from 'react';
import './Divider.scss';

const Divider = ({text}) => {
return (<div className='divider'>
    <div className='line'></div>
    <div className='divider-text'>{text}</div>
    <div className='line'></div>
    </div>);
}

export default Divider;
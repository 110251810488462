import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

const Header = ({ text, smallText }) => {
    return (
        <div className='header' style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${process.env.PUBLIC_URL}/tunnelma_haku_header.jpg)` }}>
            <h1 className='header-text'>{text}</h1>
            <span className='header-small-text'>{smallText}</span>
        </div>
    );
}

Header.propTypes = {
    text: PropTypes.string.isRequired,
    smallText: PropTypes.string,
}

export default Header;
import React from 'react';
import PropTypes from 'prop-types';
import './Tag.scss';

const Tag = ({ text, id, isChecked, handleClick}) => {
    return (<button value={id} onClick={handleClick} 
        className={`tag tag-text tag__${isChecked ? 'checked': 'unchecked'}`}>
        {text}
        </button>);
    
}

Tag.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
}

export default Tag;